<template>
  <div id="AD">
    <transition name="load">
      <div class="loading" v-if="loading" key="1">
        <img src="../../assets/loading.gif" alt="" />
      </div>
      <div class="AD-inner" v-else key="2">
        <div class="AD-left">
          <h1>{{ data.judul }}</h1>
          <div class="AD-mid">
            <div class="AD-mid-l">
              <p>{{ data.tanggal }}</p>
              <p>{{ data.moment }}</p>
            </div>
            <!-- <p>Author</p> -->
          </div>
          <img :src="data.foto" alt="" />
          <p style="white-space: pre-line">
            {{ data.isi }}
          </p>
        </div>

        <div class="AV-other">
          <div class="PI-search">
            <button v-on:click="back()" class="search">
              <p>&lt;&lt; Kembali</p>
              <!-- <button type="submit" class="searchButton">
              <i class="fa fa-search"></i>
            </button> -->
            </button>
          </div>

          <h3>Artikel Lainnya</h3>
          <div
            class="AV-other-post"
            v-for="(item, index) in dataSamping"
            :key="index"
          >
            <h4>{{ item.judul }}</h4>
            <p class="AV-other-h">{{ item.tanggal }}</p>
            <p><a v-on:click="detail(item.judul)">Baca >>></a></p>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import konten from "../../services/konten.service";
import moment from "moment";

export default {
  name: "ArtikelDetail",
  data() {
    return {
      nama: this.$route.params.nama,
      loading: true,
      data: [],
      dataSamping: [],
    };
  },
  methods: {
    get() {
      konten
        .getDetail("artikel", this.nama)
        .then((res) => {
          this.data = res.data.data;

          moment.locale("id");
          var tanggal = new Date(this.data.tanggal);
          this.data.tanggal = moment(tanggal).format("dddd, Do MMMM YYYY");
          this.data.moment = moment(tanggal).fromNow();

          konten
            .getLimit("artikel", this.data.id, 5)
            .then((res) => {
              this.dataSamping = res.data.data;
              for (var i in this.dataSamping) {
                moment.locale("id");
                this.dataSamping[i].tanggal = moment(
                  new Date(this.dataSamping[i].tanggal)
                ).fromNow();
                // this.loading = false;
              }
              this.loading = false;
            })
            .catch((e) => {
              console.log(e);
            });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    detail(judul) {
      judul = judul.toLowerCase().replace(/[^a-zA-Z ]/g, "");
      judul = judul.replace(/ /g, "_");
      console.log(judul);
      this.$router.push("/artikel/" + judul);
    },
    back() {
      this.$router.push("/artikel");
    },
  },
  async mounted() {
    await this.get();
    var n = this.nama.toLowerCase().split("_");
    for (var i = 0; i < n.length; i++) {
      n[i] = n[i].charAt(0).toUpperCase() + n[i].substring(1);
    }
    n = n.join(" ");
    document.title = "Artikel | " + n;
  },
};
</script>

<style>
#AD {
  width: 100%;
  padding-top: 100px;
}

.load-enter-active,
.load-leave-active {
  transition: opacity 0.5s ease-in;
}

.load-enter,
.load-leave-to {
  opacity: 0;
}

.loading {
  position: fixed; /* or absolute */
  margin-top: -64px;
  margin-left: -64px;
  top: 50%;
  left: 50%;
  transition: display 0.5ms ease;
}

.loading img {
  max-width: 128px;
}

.AD-inner {
  width: 90%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: auto 30.5vw;
  grid-template-rows: auto;
  grid-gap: 3em;
}
.AD-left h1 {
  font-weight: 700;
  width: 100%;
  color: #248842;
  margin-bottom: 0.5em;
}
.AD-mid {
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: #808080;
}
.AD-mid-l {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
  width: 100%;
}
.AD-left img {
  width: 100%;
  object-fit: contain;
  max-height: 400px;
  /* border: #248842 2px solid; */
  object-position: 0 0;
  margin-bottom: 1em;
}
.AD-left p {
  /* font-size: 1.12rem; */
  margin-bottom: 1em;
}
.AV-other {
  position: fixed;
  left: 65vw;
}
.AV-other h3 {
  font-weight: 400;
  margin-bottom: 0.75em;
  color: #248842;
}
.AV-other-post {
  display: grid;
  grid-template-columns: auto 5.5vw;
  grid-template-rows: auto auto;
  grid-column-gap: 0.75em;
  grid-row-gap: 0.25em;
  margin-bottom: 0.9em;
}
.AV-other-post h4 {
  grid-column: 1/2;
  grid-row: 1/2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #248842;
}
.AV-other-post p {
  grid-column: 2/3;
  grid-row: 1/3;
}
.AV-other-post .AV-other-h {
  grid-column: 1/2;
  grid-row: 2/3;
  color: #808080;
  font-size: 0.9rem;
}
.AV-other-post a {
  color: #808080;
  text-decoration: none;
  font-size: 0.9rem;
  cursor: pointer;
}

.search {
  width: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  padding: 1rem;
  border: 1.5px solid #006837;
  border-radius: 10px;
  background-color: white;
  color: black;
  transition: background-color 0.25s ease-in, color 0.25s ease-in;
  cursor: pointer;
  margin-bottom: 2em;
}

.search:hover {
  background-color: #006837;
  color: white;
}

.search p {
  font-size: 1.25rem;
}

.searchTerm {
  width: 100%;
  border-radius: 20px;
  border: none;
  padding: 5px;
  outline: none;
  color: #9dbfaf;
}

.searchTerm:focus {
  color: black;
}

.searchButton {
  width: 40px;
  height: 36px;
  text-align: center;
  color: grey;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  outline: none;
}
@media only screen and (max-width: 1013px){
  .AD-inner {
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    grid-gap: 3em;
  }
  .AV-other {
    position: relative;
    left: 0;
    margin-left: 0em;
    width: 98%;
    margin: 0 auto;
  }
  .AV-other-post {
    grid-template-columns: auto 20%;
  }
}
</style>
